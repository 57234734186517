<!-- 首页 -->
<template>
  <!--系统、应用/应用解决方案、消息、帮助(开发文档、功能更新)、反馈中心、快速导航-->
  <div class="page-main-home-box">
    <div class="header-box">
      <div class="header-content-box font14">
        <section class="logo">
          <!-- <img class="logo-img" v-if="userInfo.details.enterpriseAccount.iconUrl" :src="userInfo.details.enterpriseAccount.iconUrl" />
          <h2 class="logo-title" v-else>{{ userInfo.details.enterpriseAccount.name }}</h2> -->
          <img class="logo-img" src="@/assets/img/logo.png" />
          <h2 class="logo-title"> {{ systemName }} </h2>
        </section>
        <section class="module-class">
          <!-- <div class="module-class-item">产品<i class="el-icon-arrow-down el-icon--right"></i></div>
          <div class="module-class-item">资源社区<i class="el-icon-arrow-down el-icon--right"></i></div>
          <div class="module-class-item">帮助支持<i class="el-icon-arrow-down el-icon--right"></i></div>
          <div class="module-class-item" @click="toHelp">帮助中心</div> -->
        </section>
        <w-user-info :userInfo="userInfo" />
      </div>
    </div>
    <div id="main-scroll-box">
      <div class="main-box">
        <el-row :gutter="10">
          <el-col :span="6">
            <section class="main-box-item flex-column">
              <div class="main-box-item-title">收藏列表</div>
              <div class="main-box-item-content flex-1 h-0">
                <w-collect class="h100" />
              </div>
            </section>
          </el-col>
          <el-col :span="12" v-if="!isTenant">
            <section class="main-box-item">
              <div class="main-box-item-title">我的应用</div>
              <div class="main-box-item-content">
                <!-- <div class="app-table-query">
                  <el-form :inline="true" :model="formApp">
                    <el-form-item label="">
                      <el-input clearable placeholder="请输入应用名称" prefix-icon="el-icon-search" v-model="formApp.appName"></el-input>
                    </el-form-item>
                    <el-form-item label="">
                      <el-input clearable placeholder="请输入应用编码" prefix-icon="el-icon-search" v-model="formApp.appCode"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-button type="primary" icon="el-icon-search" round @click="onQuery">查询</el-button>
                    </el-form-item>
                  </el-form>
                </div> -->
                <div class="app-table-list">
                  <section class="app-table-list-item cursor" v-for="_item in applicationList" :key="_item.id">
                    <div class="app-title">
                      <span class="title">{{ _item.appName }}</span>
                      <span class="code mt5">{{ _item.appCode }}</span>
                    </div>
                    <div class="app-go">
                      <el-tooltip effect="dark" :content="item.name" placement="top" v-for="item in getCurAppAuth(_item.appCode)" :key="item.id">
                        <img @click="goLocalApp(item, _item.appCode)" :src="item.icon" />
                      </el-tooltip>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          </el-col>
          <el-col :span="isTenant? 18 : 6">
            <section class="main-box-item">
              <div class="main-box-item-title">系统</div>
              <div class="main-box-item-content" style="display: flex;  flex-wrap: wrap; height: 100px">
                <div class="app-item ml5" v-for="item in globalApp" :key="item.id" @click="goGlobalApp(item)">
                  <img :src="item.icon" />
                  <span>{{ item.name }}</span>
                </div>
              </div>
              <div class="main-box-item-title">快捷导航</div>
              <div class="main-box-item-content" style="overflow-y: auto;height: 200px;">
                <!-- <p class="quick-navigation">
                  <el-link icon="el-icon-link" href="" target="_blank">开发平台管理后台</el-link>
                </p> -->
               
              </div>
            </section>
          </el-col>
        </el-row>
      </div>
      <!-- <div class="help-docs-box">
        <section class="help-docs-title"><h3>帮助文档</h3></section>
        <div class="help-docs-item-box">
          <el-row :gutter="10">
            <el-col :span="6" v-for="item in helpDocs" :key="item.title">
              <div class="help-docs-item" @click="goHelp(item)">
                <div class="font14">{{ item.title }}</div>
                <div style="color: #2563eb">查看所有<i class="el-icon-right"></i></div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import WCollect from './collectList.vue'
import WUserInfo from '@/layout/components/userInfo'
import { mapGetters, mapState } from 'vuex'
import { child_app_path } from '@/utils/constant'
import http from '@/utils/axios'
import { getPath } from '@/utils'
export default {
  components: { WUserInfo, WCollect },
  data() {
    return {
      systemName: process.env.VUE_APP_TITLE,
      formApp: {
        appCode: '',
        appName: '',
      },
      helpDocs: [
       
      ],
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    ...mapState(['globalApp', 'localApp', 'allLocalApp', 'applicationList']),
    isTenant(){
      // 判断是否是租户账号
      return this.userInfo?.details?.accountType == 'enterprise_user'
    }
  },
  methods: {
    getCurAppAuth(appCode) {
      return this.allLocalApp[appCode]?.filter((ops) => ops.type !== 'scheme_menus') || {}
    },
    // 查询应用
    onQuery() {},
    // goGlobalApp
    goGlobalApp(item) {
      let path = getPath(item.children) || item.code
      window.location.href = `${path}?p_id=${item.id}`
    },
    // goLocalApp
    goLocalApp(item, appCode) {
      let appSysItem = _.find(this.globalApp, { code: '/micro-app', rangeFlag: 'global' })
      // this.$router.push({ path: item.code + child_app_path,query: { p_id:appSysItem.id,c_id: item.id,p_app_code: appCode} });
      if (appSysItem) {
        window.location.href = `${item.code}${child_app_path}?p_id=${appSysItem.id}&c_id=${item.id}&p_app_code=${appCode}`
      } else {
        this.$message.error('您没有权限')
      }
    },
    // goHelp
    goHelp(item) {
      window.open(item.url, '_blank')
    },
    // 退出登录
    handleCommand(command) {
      if (command === 'logout') {
        this.handleLogout()
      }
    },
    handleLogout() {
      this.$confirm('确定退出登录吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        http.post('/logout', null, { baseURL: '' })
      })
    },
    // 显示帮助文档
    toHelp() {
      window.open(`/helpDoc`, '_blank')
    },
  },
}
</script>
<style lang="scss" scoped>
.page-main-home-box {
  // min-width: 100vw;
  // min-height: 100vh;
  // background-color: #fff;
  #main-scroll-box {
    overflow-y: auto;
    margin-top: 80px;
    height: calc(100vh - 80px);
    padding-bottom: 16px;
  }
  .header-box {
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    border-bottom: 1px solid rgba(51, 11, 11, 0.06);
    z-index: 5;
    width: 100%;
    .header-content-box {
      display: flex;
      // max-width: 1200px;
      padding: 0 20px;
      width: 100%;
      height: 80px;
      align-items: center;
      font-weight: 500;
      margin: auto;
      justify-content: space-between;

      .logo {
        display: flex;
        align-items: center;
        cursor: pointer;
        .logo-img {
          width: auto;
          height: 40px;
        }
        .logo-title {
          white-space: nowrap;
          text-align: center;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 1.2px;
          font-family: cursive;
        }
      }

      .module-class {
        display: flex;
        width: 700px;
        .module-class-item {
          cursor: pointer;
          padding: 0 30px;
        }
      }

      .logout {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        .logout-logo {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
        .logout-name {
          font-size: 12px;
        }
      }
    }
  }
  .main-box {
    padding: 8px 16px 0 16px;

    .main-box-item {
      background-color: #fff;
      height: 400px;
      border-radius: 8px;
      border: 1px solid rgba(51, 11, 11, 0.06);
      padding: 0 8px;
      .main-box-item-title {
        height: 50px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(51, 11, 11, 0.06);
        font-size: 14px;
        font-weight: 500;
      }

      .main-box-item-content {
      }
    }

    .app-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: bolder;
      // margin-top: 8px;
      cursor: pointer;
      height: 90px;
      & > img {
        width: 45px;
        height: 45px;
        overflow: hidden;
        margin-bottom: 5px;
        transition: all 0.3s;
      }

      &:hover {
        img {
          width: 50px;
          height: 50px;
        }
        font-size: 14px;
        color: #33bba7;
      }
    }

    .quick-navigation {
      padding: 8px 0;
    }

    .app-table-query {
      margin-top: 16px;
    }

    .app-table-list {
      height: calc(400px - 50px);
      overflow-y: auto;
      .app-table-list-item {
        border-bottom: 1px solid rgba(51, 11, 11, 0.06);
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        height: 58px;
        padding: 14px 12px;
        border-radius: 4px;
        &:hover {
          background-color: #f2f4f7;
          border-bottom: none;
          .app-go {
            display: flex;
          }
        }
        .app-go {
          display: none;
          align-items: center;
          justify-content: flex-end;
          cursor: pointer;
          width: 180px;
          img {
            width: 32px;
            height: 32px;
          }
        }

        .app-title {
          .title {
            display: block;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 0em;
            color: #1f2126;
            overflow: hidden;
            white-space: nowrap;
            width: 100%;
            text-overflow: ellipsis;
          }

          .code {
            display: block;
            overflow: hidden;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0em;
            color: #1f212680;
            white-space: nowrap;
            width: 100%;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .app-table-pagination {
    }
  }
  .help-docs-box {
    padding: 8px 16px 0 16px;

    .help-docs-title {
      background-color: #fff;
      height: 60px;
      line-height: 60px;
      text-align: center;
      text-decoration: underline;
      // font-size: 18px;
      // font-weight: bolder;
      // border-bottom: 1px solid rgba(51,11,11,.06);
    }

    .help-docs-item-box {
      // padding-top: 50px;
      background-color: #fff;
      padding: 16px;
      .help-docs-item {
        cursor: pointer;
        width: 100%;
        height: 80px;
        // line-height: 80px;
        overflow: hidden;
        border: 1px solid rgba(51, 11, 11, 0.06);
        border-radius: 8px;
        text-align: center;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        margin-bottom: 8px;

        &:hover {
          box-shadow: 0px 8px 12px 0px #ccc;
        }
      }
    }
  }
}
</style>
